import './App.css';
import Data from './components/ProductData'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
// import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa'
import { CiFacebook, CiInstagram, CiLinkedin } from 'react-icons/ci'

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

function App() {
  const stickyHeaderRef = useRef();

  const stickyHeader = () => {
    const header = stickyHeaderRef?.current;
    const offsetTop = header.offsetTop;

    if (window.pageYOffset >= offsetTop) {
      header.classList.add('header-sticky')
    } else {
      header.classList.remove('header-sticky')
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', stickyHeader, false);

    return () => {
      window.removeEventListener('scroll', stickyHeader, false);
    }
  }, []);

  const [currentSectionId, setCurrentSectionId] = useState(0);

  function scrollElement(id) {
    const currentSection = document.getElementById(id);
    const offsetHeader = stickyHeaderRef?.current.offsetHeight;
    const yOffset = -offsetHeader - 10;
    const y = currentSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
    setCurrentSectionId(id);
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  function scrollSectionElement() {
    const section = [...document.querySelectorAll(".section")].find(e => e.getBoundingClientRect().top >= 0)
    const currentSection = section?.dataset.id;
    setCurrentSectionId(+currentSection);
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', scrollSectionElement, false);

    return () => {
      window.removeEventListener('scroll', scrollSectionElement, false);
    }
  }, []);

  return (
    <div>
      {/* Header */}
      <header className='grid grid-cols-2 sm:grid-cols-3 gap-2 z-50 mb-20'>
        <div className='sm:col-span-1'></div>
        <div className='col-span-2 pl-5 sm:col-span-1 sm:flex sm:justify-center'>
          <img src='./images/logo.png' alt='logo' className='w-auto lg:h-40 h-24 xs:h-32 py-5' />
        </div>
        <div className='col-span-2 top-1 right-1 absolute sm:col-span-1 text-end pr-5 pt-5'>
          <p className='header-text'>
            <a href="https://goo.gl/maps/MgrzSQHoeJtShVYYA">Jovana Pačua 57</a>
          </p>
          <p className='header-text'>24000 Subotica</p>
          <p className='header-text'>
            <a href="mailto:office@grif.rs">office@grif.rs</a>
          </p>
          <p className='header-text'>
            <a href="tel:0605677600">060 567 76 00</a>
          </p>
          <p className='header-text'>
            <a href="tel:0616141109">061 614 11 09</a>
          </p>
          <p className='header-text'>
            <a href="tel:024566622">024 566 622</a>
          </p>
        </div>
      </header>

      {/* Menu */}
      <div className='menu-container' ref={stickyHeaderRef}>
        <div className='grid grid-cols-2 xs:grid-cols-4 lg:grid-cols-8 gap-2'>
          {Data.map((item) => (
            <button
              className={`menu-btn ${currentSectionId === item.id ? 'isActive' : ''}`}
              onClick={() => scrollElement(item.id)}
              key={item.id}
            >
              {item.title}
            </button>
          ))}
        </div>
      </div>

      {/* Hero - Slider */}

      <div>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="mySwiper">
          <SwiperSlide>
            <img src='./images/baner01.webp' alt='banner' className='rounded-md border-2 border-primary' />
          </SwiperSlide>
          <SwiperSlide>
            <img src='./images/baner02.webp' alt='banner' className='rounded-md border-2 border-primary' />
          </SwiperSlide>
          <SwiperSlide>
            <img src='./images/baner03.webp' alt='banner' className='rounded-md border-2 border-primary' />
          </SwiperSlide>
        </Swiper>
      </div>

      {/* Products */}
      <div className='product-container'>
        {Data.map((item) => (
          <section key={item.id} id={item.id} data-id={item.id} className='section w-full h-fit bg-white rounded-md border-2 border-primary pb-5'>
            <div>
              <h1>
                {item.title}
              </h1>
            </div>
            <div className='flex gap-2 sm:gap-10 pl-3 sm:pl-5 pr-3 sm:pr-10 justify-center align-center'>
              <CustomSlider item={item} />
              <div className='w-[50%] h-full text-justify text-[8px] xs:text-[10px] sm:text-sm lg:text-lg overflow-hidden'>
                <p>{item.text}</p>
              </div>
            </div>
          </section>
        ))}
      </div>

      {/* Footer */}
      <div className='px-5'>
        <div className="bg-white h-[40vh] p-4 grid grid-cols-2 md:grid-cols-3">
          <div className="hidden md:block md:col-span-1 pl-5">
            <h2 className="text-lg font-semibold mt-3">Adresa:</h2>
            <p className='header-text'>Jovana Pačua 57 </p>
            <p className='header-text'>24000 Subotica</p>
            <h2 className='text-lg font-semibold mt-2 '>E-mail:</h2>
            <p className='header-text'>
              <a href="mailto:office@grif.rs">office@grif.rs</a>
            </p>
            <h2 className='text-lg font-semibold mt-2'>Telefon:</h2>
            <p className='header-text'>
              <a href="tel:0605677600">060 567 76 00</a>
            </p>
            <p className='header-text'>
              <a href="tel:0616141109">061 614 11 09</a>
            </p>
            <p className='header-text'>
              <a href="tel:024566622">024 566 622</a>
            </p>
            <div className='flex pt-3 gap-3'>
              <a href='https://www.facebook.com/people/GRIF-%C5%A0tamparija/61550099083579/'>
                <CiFacebook size={34} />
              </a>
              <a href='https://www.instagram.com/grif.rs'>
                {/* <img src='./images/instalogo.webp' alt='instagram' width={32} height={32} /> */}
                <CiInstagram size={34} />
              </a>
              <a href='https://www.linkedin.com/company/stamparijagrif'>
                <CiLinkedin size={34} />
              </a>
            </div>
          </div>

          <div className="col-span-2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2768.0313324397016!2d19.675859162329807!3d46.070416413144265!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4743612883d70d95%3A0x8a9334e6141f9288!2s%C5%A0tamparija%20Grif!5e0!3m2!1sen!2srs!4v1692617820279!5m2!1sen!2srs"
              width="100%"
              height="100%"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
      <div className='flex justify-center py-5'>
        All rights reserved &copy; Copyright by &nbsp; <a href='https://dev78.net' className='font-semibold'>&lt;dev&gt;78</a>
      </div>
    </div>
  );
}

// SLIDER APP

function CustomSlider({ item }) {
  const [silderImage, setSliderImage] = useState(item.image);

  useEffect(() => {
    setSliderImage(item.image1);
  }, [item]);

  function onChangeImage(image) {
    setSliderImage(image);
  }

  return (
    <div className='w-[50%] h-full justify-center overflow-hidden'>
      <img src={silderImage} alt='glavna_slika' className='w-[100%] h-full' />
      <div className='grid grid-cols-1 xs:grid-cols-2  sm:flex gap-3 w-[50%] xs:w-[100%] sm:w-[23%] pt-5'>
        <img onClick={() => onChangeImage(item.image1)} src={item.image1} alt='slika01' className='' />
        <img onClick={() => onChangeImage(item.image2)} src={item.image2} alt='slika02' />
        <img onClick={() => onChangeImage(item.image3)} src={item.image3} alt='slika03' />
        <img onClick={() => onChangeImage(item.image4)} src={item.image4} alt='slika04' />
      </div>
    </div>
  )
}

export default App;


