const data = [
  {
    id: 1,
    title: 'Digitalna štampa',
    image1: './images/digital01.webp',
    image2: './images/digital02.webp',
    image3: './images/digital03.webp',
    image4: './images/digital04.webp',
    text: `Usluga digitalne štampe pruža efikasno rešenje za štampanje papira različitih formata, gramature i teksture. Bez obzira da li se radi o  brošurama, letcima, posterima, vizit kartama, fasciklama ili drugim štampanim materijalima, digitalna štampa pruža rezultate visokog kvaliteta sa brzim vremenom izrade. Koristeći najsavremeniju opremu, digitalna štampa pruža ekonomična rešenja, posebno za manje i srednje tiraže. Uz mogućnost štampanja na različitim formatima i debljinama papira digitalni proces štampe omogućava preciznu reprodukciju teksta, slika i grafika, obezbeđujući oštre detalje i žive boje. Zahvaljujući brzim mašinama i efikasnim procesima, usluge digitalne štampe mogu da isporuče visokokvalitetne otiske u kratkom vremenskom roku.`,
  },
  {
    id: 2,
    title: 'Tekstil',
    image1: './images/tshirt01.webp',
    image2: './images/tshirt02.webp',
    image3: './images/tshirt03.webp',
    image4: './images/tshirt04.webp',
    text: `Usluga štampe na tekstilu nudi fleksibilnost u pogledu opcija dizajna, izbora materijala, kombinacija boja i tehnika štampe. Cilj nam je da  svaki štampani tekstil bude prilagođen specifičnim zahtevima i preferencijama klijenta. Ovaj proces omogućava pojedincima, preduzećima i organizacijama da prilagode i personalizuju tkanine za različite namene, uključujući odevne predmete, promotivni materijal, dekoraciju doma, i još mnogo toga. Proces uključuje upotrebu tehnika štampe kao što su sito štampa, direktna štampa na tkaninu (DTG), direktna štampa na transfer film (DTF) i druge.`,
  },
  {
    id: 3,
    title: 'Offset štampa',
    image1: './images/offset01.webp',
    image2: './images/offset02.webp',
    image3: './images/offset03.webp',
    image4: './images/offset04.webp',
    text: `Usluge ofset štampe pružaju profesionalna rešenja visokog kvaliteta. Koristeći naprednu opremu, efikasno proizvode velike količine otisaka. Nude mogućnosti prilagođavanja izbora papira, završne obrade i premaza. Bilo da su u pitanju brošure, letci, posteri, katalozi, knjige ili pakovanja, ofset štampa pruža izvanrednu reprodukciju slike i dosledne rezultate. Ofset štampa je ekonomična za masovnu proizvodnju, obezbeđujući konstantne rezultate.`,

  },
  {
    id: 4,
    title: 'Reklamni materijal i pokloni',
    image1: './images/gifts01.webp',
    image2: './images/gifts02.webp',
    image3: './images/gifts03.webp',
    image4: './images/gifts04.webp',
    text: `Usluga štampe na reklamnom materijalu pruža profesionalna rešenja za brendiranje materijala uglavnom namenjenog promociji. Ova vrsta usluge omogućava personalizaciju i štampanje reklamnih materijala kao što su hemijske, upaljači, rokovnici, notesi, privesci i drugi promotivni materijali. Za usluge štampe na reklamnom materijalu koristimo kvalitetnu štamparsku opremu i tehnologije kako bi obezbedili visok kvalitet štampe. Mogućnosti obuhvataju žive boje, oštre detalje i visoku rezoluciju, što rezultira privlačnim i profesionalnim izgledom štampanog materijala. U saradnji sa firmom Brand it all iz Subotice, u ponudi imamo širok asortiman reklamnog materijala koji će zadovoljiti sve potrebe vašeg brenda.`,
  },
  {
    id: 5,
    title: 'Lasersko graviranje',
    image1: './images/engrave01.webp',
    image2: './images/engrave02.webp',
    image3: './images/engrave03.webp',
    image4: './images/engrave04.webp',
    text: `Usluga laserske gravure se fokusira na personalizaciju predmeta putem preciznog graviranja uz pomoć lasera. Ovaj proces omogućava graviranje različitih materijala kao što su drvo, staklo, metal, plastika i koža, dajući im jedinstveni i personalizovani izgled. Laserska gravura se koristi za urezivanje imena, logotipa, dizajna ili poruka na predmete kao što su medalje, pehari, šolje, hemijske, drvene kutije i mnogo drugih. Laserska tehnologija omogućava izuzetnu preciznost i detaljnost graviranja, stvarajući trajne i visokokvalitetne rezultate.`,
  },
  {
    id: 6,
    title: 'Lasersko sečenje',
    image1: './images/cutting01.webp',
    image2: './images/cutting02.webp',
    image3: './images/cutting03.webp',
    image4: './images/cutting04.webp',
    text: `Usluga laserskog sečenja je namenjena za precizno sečenje različitih materijala uz pomoć visokopreciznog laserskog snopa. Ova tehnologija omogućava sečenje materijala poput drveta, akrila, tkanine, plastike i mnogih drugih, obezbeđujući čist i tačan rez bez potrebe za tradicionalnim alatima. Lasersko sečenje se koristi za izradu plaketa, reklamnih tabli, šablona, privezaka i još mnogo toga.Prednosti laserskog sečenja uključuju visoku brzinu, ponovljivost i mogućnost sečenja složenih oblika i detaljnog dizajna.`,
  },
  {
    id: 7,
    title: 'Folija',
    image1: './images/folio01.webp',
    image2: './images/folio02.webp',
    image3: './images/folio03.webp',
    image4: './images/folio04.webp',
    text: `Usluge montaže i sečenja folije pruža širok spektar mogućnosti kao sto su isecanje nalepnica, sečenje veoma detaljnog dizajna, sečenje raznih vrsta folija na precizne dimenzije kao i montažu iste na objekte ili predmete po zahtevu klijenta. Ova usluga se najviše koristi kod brendiranja lokala, vozila i drugih objekata ali i za personalne dekorativne nalepnice.`,
  },
  {
    id: 8,
    title: 'Štampa velikih formata',
    image1: './images/bigf01.webp',
    image2: './images/bigf02.webp',
    image3: './images/bigf03.webp',
    image4: './images/bigf04.webp',
    text: `Usluga štampe velikog formata omogućava štampanje materijala velikih dimenzija. Ova vrsta štampe je idealna za izradu plakata, banera, bilborda, velikih grafika, promotivnih materijala za sajmove i događaje, i drugih reklamnih ili dekorativnih elemenata. U procesu se koriste specijalizovane mašine koje omogućavaju štampanje na različitim materijalima poput papira, vinila, platna ili PVC-a kao i širok spektar opcija dorade.`,
  },
];
export default data;